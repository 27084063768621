import { caseStudiesNavigationData } from '@/components/CaseStudies/data';
import Layout from '@/components/Layout';
import { CaseStudy } from '@/sections/CaseStudy';
import { ReviewHighlight } from '@/sections/CaseStudy/highlights/review';
import LandonOrrAvatarImage from '@/static/assets/images/CaseStudies/profile-landon-orr.png';
import { seoConfiguration } from '@/utils/seo-config';

const cowbellData = caseStudiesNavigationData.cowbell;

if (!cowbellData) {
  throw new Error('Please add cowbell case study data');
}

const CowbellCaseStudyPage = () => (
  <Layout {...seoConfiguration['case-studies'].cowbell}>
    <CaseStudy
      id="cowbell"
      {...cowbellData}
      description={`Cowbell is a cyber insurance company. All of their business interactions with clients are highly dependent on their availability hence any disruptions would be preventing the company from functioning. Cowbell's infrastructure is spread across multiple regions in the US and Europe, all hosted on a single cloud provider, AWS.`}
      keyAkuityPlatformBenefits={[
        'Substantially improved managing Argo CD at scale',
        'Acquiring directly through the AWS Marketplace',
        'Handling multiple git repos at once'
      ]}
      content={[
        {
          title: 'The Operational Burden of Managing Argo CD at Scale',
          description: (
            <>
              <p>
                They knew from the start that Argo CD was the way to go, but the
                company needed something to take care of the operational burden
                of maintaining it on a large scale.
              </p>

              <p>
                What drove their interest in the Akuity Platform was the ease of
                deploying and managing Argo CD at scale as well as saving on
                management overhead and maintaining high availability and
                reliability.
              </p>
            </>
          )
        },
        {
          title:
            'Akuity Platform Features Make Running Argo CD at Scale a Pleasure',
          description: (
            <>
              <p>
                What Cowbell team loved about the Akuity Platform was its
                user-friendly interface, across the board audit log
                functionality, and the ease of deploying new Kubernetes clusters
                with maintaining high-visibility of what’s going on.
              </p>

              <p>
                The team also appreciated the Platform’s ability to handle
                multiple git repos and the way they are able to horizontally
                scale their infrastructure.
              </p>
            </>
          )
        }
      ]}
      highlight={
        <ReviewHighlight
          review="“Acquiring software directly from the vendor through the AWS Marketplace was a great selling-point. Thanks to this we were able to almost instantly start using the Akuity Platform after making the decision to move from Argo CD Open Source. It saved us around 4 months!”"
          author={{
            avatar: {
              src: LandonOrrAvatarImage,
              alt: 'Landon Orr Staff SRE at Cowbell profile picture'
            },
            name: 'Landon Orr',
            position: 'Staff SRE at Cowbell'
          }}
        />
      }
    />
  </Layout>
);

export default CowbellCaseStudyPage;
