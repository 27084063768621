import QuoteIcon from '@/static/assets/icons/CaseStudy/icn-quote.svg';

import './styles.scss';

type ReviewHighlightProps = {
  className?: string;
  review: string;
  author: {
    avatar: {
      src: string;
      alt: string;
    };
    name: string;
    position: string;
  };
};

export const ReviewHighlight = (props: ReviewHighlightProps) => (
  <div
    className={`${props.className} border-1 rounded-md highlight-review-box`}
  >
    <div className="review-container">
      <QuoteIcon className="icon" />
      <p className="review-text">{props.review}</p>
    </div>
    <div className="author">
      <img className="avatar" {...props.author.avatar} />
      <div className="author-detail">
        <h6>{props.author.name}</h6>
        <h6 className="regular">{props.author.position}</h6>
      </div>
    </div>
  </div>
);
